import React from 'react';
import styled from 'styled-components';

interface propsFooterSocialMediaIcons {
  facebookUrl?: string;
  instagramUrl?: string;
}

const FacebookSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <path
      d="M22 11.07C22 4.95 17.08 0 11 0S0 4.95 0 11.07C0 16.59 4.02 21.17 9.28 22v-7.73h-2.8v-3.2h2.8V8.63c0-2.77 1.64-4.3 4.16-4.3 1.2 0 2.46.2 2.46.2v2.73h-1.4c-1.37 0-1.8.86-1.8 1.73v2.08h3.06l-.49 3.2h-2.56V22c5.26-.83 9.28-5.4 9.28-10.93"
      fillRule="evenodd"
    />
  </svg>
);

const InstagramSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <path
      d="M11 0c2.99 0 3.36.01 4.54.07 1.17.05 1.97.24 2.67.5a5.4 5.4 0 011.94 1.28 5.4 5.4 0 011.27 1.94c.27.7.46 1.5.51 2.67C22 7.64 22 8.01 22 11s-.01 3.36-.07 4.54a8.07 8.07 0 01-.5 2.67 5.4 5.4 0 01-1.28 1.94 5.4 5.4 0 01-1.94 1.27c-.7.27-1.5.46-2.67.51-1.18.06-1.55.07-4.54.07s-3.36-.01-4.54-.07a8.07 8.07 0 01-2.67-.5 5.4 5.4 0 01-1.94-1.28 5.4 5.4 0 01-1.27-1.94c-.27-.7-.46-1.5-.51-2.67A78.03 78.03 0 010 11c0-2.99.01-3.36.07-4.54C.12 5.3.3 4.5.57 3.8a5.4 5.4 0 011.28-1.94A5.4 5.4 0 013.79.58C4.5.3 5.3.12 6.46.07 7.64 0 8.01 0 11 0zm0 1.98c-2.94 0-3.29.01-4.44.07a6.09 6.09 0 00-2.05.37c-.51.2-.88.44-1.26.83a3.4 3.4 0 00-.83 1.26c-.15.39-.32.97-.37 2.05-.06 1.15-.07 1.5-.07 4.44 0 2.94.01 3.29.07 4.44.05 1.08.22 1.66.37 2.05.2.51.44.88.83 1.26.38.39.75.63 1.26.83.39.15.97.32 2.05.37 1.15.06 1.5.07 4.44.07 2.94 0 3.29-.01 4.44-.07a6.09 6.09 0 002.05-.37c.51-.2.88-.44 1.26-.83.39-.38.63-.75.83-1.26.15-.39.32-.97.37-2.05.06-1.15.07-1.5.07-4.44 0-2.94-.01-3.29-.07-4.44a6.09 6.09 0 00-.37-2.05 3.4 3.4 0 00-.83-1.26 3.4 3.4 0 00-1.26-.83 6.09 6.09 0 00-2.05-.37c-1.15-.06-1.5-.07-4.44-.07zm0 3.37a5.65 5.65 0 110 11.3 5.65 5.65 0 010-11.3zm0 9.32a3.67 3.67 0 100-7.34 3.67 3.67 0 000 7.34zm7.2-9.54a1.32 1.32 0 11-2.65 0 1.32 1.32 0 012.64 0z"
      fillRule="nonzero"
    />
  </svg>
);

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  svg {
    height: 22px;
    width: 22px;
    margin: 0 3px;
    fill: gray; // should have this in theme really
  }
`;

const FooterSocialMediaIcons = (props: propsFooterSocialMediaIcons) => {
  return (
    <Container>
      <a href={props.facebookUrl} target="_blank">
        {FacebookSvg}
      </a>
      <a href={props.instagramUrl} target="_blank">
        {InstagramSvg}
      </a>
    </Container>
  );
};

export default FooterSocialMediaIcons;
