import React from 'react';
import styled from 'styled-components';

interface PhoneNumberProps {
  phone: string;
}

export const PhoneNumber = ({phone}: PhoneNumberProps): JSX.Element => {
  return <PhoneContainer href={'tel:' + phone}>{phone}</PhoneContainer>;
};

PhoneNumber.defaultProps = {
  phone: '123.456.789',
};

export default PhoneNumber;

const PhoneContainer = styled.a`
  margin: 0px;
  font-family: ${({theme}) => theme.fontFaces.default};
  font-weight: ${({theme}) => theme.fontWeights.light};
  font-size: ${({theme}) => theme.fontSizes[2]}px;
  color: rgb(64, 64, 64);
  text-decoration: none;
`;
