import React from 'react';

interface EhoProps {
  color: string;
}

export const Eho = ({color}: EhoProps) => {
  return (
    <svg
      version="1.0"
      x="0px"
      y="0px"
      width="43px"
      height="21px"
      viewBox="0 0 19.8 9.992"
      enableBackground="new 0 0 19.8 9.992"
    >
      <g>
        <path
          fill={color}
          d="M8.859,9.968H9.12V9.422l0.326-0.666H9.154L9.021,9.087C9.013,9.107,9.002,9.139,8.991,9.18
		C8.979,9.139,8.967,9.107,8.959,9.087L8.825,8.756H8.534l0.325,0.666V9.968z M8.134,9.968h0.259V9.002h0.231V8.756H7.9v0.246h0.233
		L8.134,9.968L8.134,9.968z M7.592,9.968h0.262V8.756H7.592V9.968z M6.519,9.968h0.256V9.381c0-0.079-0.009-0.154-0.027-0.224
		C6.779,9.243,6.81,9.307,6.834,9.35l0.352,0.618H7.44V8.756H7.186v0.562c0,0.084,0.008,0.166,0.024,0.252
		C7.185,9.5,7.153,9.432,7.119,9.367L6.787,8.756H6.519V9.968z M5.795,8.756h-0.26v0.777c0,0.285,0.155,0.458,0.419,0.458
		c0.264,0,0.419-0.173,0.419-0.458V8.756H6.115v0.671c0,0.2-0.042,0.296-0.161,0.296c-0.117,0-0.159-0.096-0.159-0.296V8.756z
		 M5.002,9.968h0.259V9.002h0.232V8.756H4.769v0.246h0.233V9.968z M4.002,8.756H4.38c0.238,0,0.376,0.159,0.376,0.389
		c0,0.188-0.088,0.312-0.229,0.326l0.271,0.497H4.485L4.261,9.452v0.516H4.002V8.756z M4.261,8.992v0.335h0.077
		c0.1,0,0.155-0.062,0.155-0.17c0-0.112-0.054-0.165-0.162-0.165H4.261z M3.749,8.911C3.849,9.027,3.9,9.184,3.9,9.361
		c0,0.18-0.052,0.334-0.151,0.451C3.648,9.93,3.517,9.991,3.362,9.991c-0.151,0-0.284-0.062-0.385-0.179
		c-0.1-0.117-0.151-0.271-0.151-0.451c0-0.178,0.052-0.334,0.15-0.45C3.078,8.793,3.21,8.732,3.362,8.732
		C3.518,8.732,3.647,8.793,3.749,8.911 M3.638,9.361c0-0.213-0.114-0.358-0.275-0.358c-0.159,0-0.275,0.146-0.275,0.358
		c0,0.212,0.117,0.359,0.275,0.359C3.523,9.721,3.638,9.574,3.638,9.361 M2.076,8.756h0.359c0.218,0,0.356,0.159,0.356,0.404
		c0,0.267-0.126,0.413-0.368,0.413H2.335v0.395H2.076V8.756z M2.374,9.327c0.101,0,0.155-0.062,0.155-0.17
		c0-0.112-0.052-0.165-0.16-0.165H2.335v0.335H2.374z M1.276,8.756h0.36c0.218,0,0.355,0.159,0.355,0.404
		c0,0.267-0.125,0.413-0.368,0.413H1.536v0.395h-0.26V8.756z M1.575,9.327c0.101,0,0.155-0.062,0.155-0.17
		c0-0.112-0.053-0.165-0.162-0.165H1.536v0.335H1.575z M1.022,8.911c0.101,0.116,0.153,0.272,0.153,0.45
		c0,0.18-0.053,0.334-0.153,0.451C0.924,9.93,0.791,9.991,0.638,9.991c-0.153,0-0.286-0.062-0.386-0.179S0.1,9.541,0.1,9.361
		c0-0.178,0.053-0.334,0.151-0.45c0.101-0.118,0.232-0.179,0.387-0.179C0.793,8.732,0.922,8.793,1.022,8.911 M0.913,9.361
		c0-0.213-0.115-0.358-0.275-0.358c-0.161,0-0.277,0.146-0.277,0.358c0,0.212,0.118,0.359,0.277,0.359
		C0.798,9.721,0.913,9.574,0.913,9.361 M8.961,7.889L9.114,7.73C9.027,7.616,8.917,7.559,8.782,7.559
		c-0.124,0-0.23,0.049-0.311,0.145c-0.08,0.094-0.123,0.219-0.123,0.361c0,0.146,0.043,0.271,0.123,0.362
		C8.551,8.52,8.658,8.57,8.782,8.57c0.162,0,0.283-0.078,0.375-0.245c0.029-0.054,0.057-0.156,0.057-0.267
		c0-0.019-0.002-0.037-0.003-0.06H8.782v0.187h0.214C8.96,8.293,8.882,8.354,8.782,8.354c-0.13,0-0.223-0.117-0.223-0.289
		c0-0.17,0.093-0.287,0.223-0.287C8.856,7.777,8.92,7.814,8.961,7.889 M7.526,8.552h0.206V8.08c0-0.062-0.007-0.123-0.022-0.18
		C7.736,7.969,7.761,8.02,7.781,8.055l0.281,0.497h0.205V7.578H8.062v0.453c0,0.066,0.007,0.132,0.021,0.201
		C8.062,8.176,8.037,8.121,8.009,8.068l-0.266-0.49H7.526V8.552z M7.195,8.552h0.211V7.578H7.195V8.552z M6.715,8.27H6.522
		C6.527,8.456,6.635,8.572,6.81,8.572c0.189,0,0.307-0.127,0.307-0.313c0-0.073-0.02-0.132-0.056-0.179
		C7.01,8.016,6.948,7.994,6.881,7.964C6.777,7.918,6.72,7.904,6.72,7.836c0-0.055,0.036-0.092,0.095-0.092
		c0.06,0,0.096,0.042,0.097,0.115H7.1C7.098,7.665,6.996,7.559,6.811,7.559c-0.171,0-0.28,0.125-0.28,0.297
		c0,0.09,0.03,0.166,0.086,0.215c0.057,0.051,0.114,0.068,0.169,0.093C6.871,8.199,6.92,8.227,6.92,8.284
		c0,0.054-0.037,0.089-0.102,0.089C6.756,8.373,6.717,8.334,6.715,8.27 M5.979,7.578H5.77v0.625c0,0.229,0.126,0.367,0.337,0.367
		c0.213,0,0.337-0.139,0.337-0.367V7.578H6.235v0.539c0,0.16-0.032,0.238-0.129,0.238c-0.094,0-0.128-0.078-0.128-0.238L5.979,7.578
		L5.979,7.578z M5.571,7.703c0.08,0.093,0.123,0.218,0.123,0.361c0,0.145-0.043,0.269-0.123,0.361
		C5.492,8.521,5.385,8.57,5.261,8.57c-0.122,0-0.229-0.049-0.309-0.145C4.872,8.333,4.83,8.209,4.83,8.064s0.042-0.27,0.122-0.361
		c0.081-0.096,0.187-0.145,0.31-0.145C5.387,7.559,5.491,7.607,5.571,7.703 M5.482,8.064c0-0.17-0.092-0.287-0.221-0.287
		c-0.128,0-0.222,0.117-0.222,0.287c0,0.172,0.095,0.289,0.222,0.289C5.391,8.354,5.482,8.236,5.482,8.064 M4.068,8.552h0.208V8.157
		h0.265v0.395h0.208V7.578H4.541v0.381H4.276V7.578H4.068V8.552z M3.331,8.552h0.417V8.354H3.539V7.578H3.331V8.552z M2.76,7.578
		h0.257l0.284,0.974h-0.22L3.047,8.41H2.729L2.694,8.552H2.477L2.76,7.578z M3,8.227L2.888,7.775L2.776,8.227H3z M1.984,7.578H1.775
		v0.625c0,0.229,0.125,0.367,0.337,0.367c0.213,0,0.337-0.139,0.337-0.367V7.578H2.241v0.539c0,0.16-0.033,0.238-0.129,0.238
		c-0.095,0-0.128-0.078-0.128-0.238V7.578z M1.268,8.57c-0.123,0-0.23-0.049-0.31-0.144c-0.08-0.094-0.122-0.218-0.122-0.362
		s0.042-0.27,0.122-0.362c0.079-0.093,0.186-0.144,0.31-0.144c0.123,0,0.229,0.049,0.31,0.144C1.658,7.796,1.7,7.921,1.7,8.064
		c0,0.121-0.029,0.224-0.088,0.312l0.066,0.077l-0.12,0.154L1.476,8.514C1.411,8.553,1.344,8.57,1.268,8.57 M1.294,8.307l0.12-0.152
		L1.462,8.21C1.48,8.163,1.489,8.115,1.489,8.064c0-0.17-0.094-0.287-0.223-0.287s-0.22,0.117-0.22,0.287
		c0,0.172,0.092,0.289,0.227,0.289c0.02,0,0.036-0.004,0.053-0.012L1.294,8.307z M0.298,8.552h0.464V8.354H0.506V8.154h0.256V7.966
		H0.506V7.768h0.256V7.578H0.298V8.552z M3.144,4.162h3.144V3.284H3.144V4.162z M3.144,5.465h3.144V4.587H3.144V5.465z M4.701,1.076
		L1.699,2.832v3.454H7.73V2.832L4.701,1.076z M8.693,3.766v3.425H0.765V3.766H0V2.747L4.673,0l4.729,2.69v1.076H8.693z"
        />
        <path
          fill={color}
          d="M14.938,0.532c0.466,0,0.834,0.37,0.834,0.834c0,0.465-0.368,0.834-0.834,0.834
		c-0.464,0-0.834-0.369-0.834-0.834C14.104,0.902,14.475,0.532,14.938,0.532 M14.07,5.012c-0.216-1.358-0.238-1.537-0.238-1.692
		c0-0.5,0.369-0.834,0.787-0.834c0.701,0,0.797,0.596,0.881,1.227l0.035,0.262h1.905c0,0.739-0.417,0.811-1.048,0.811
		c-0.273,0-0.514-0.012-0.738-0.036l0.154,1.001h2.646l1.272,3.442C19.775,9.336,19.8,9.456,19.8,9.516
		c0,0.106-0.059,0.154-0.299,0.154c-0.451,0-0.57-0.167-0.69-0.477l-0.869-2.346h-3.587L14.07,5.012z M17.751,7.348
		c-0.168,1.488-1.441,2.646-2.979,2.646c-1.655,0-3.003-1.347-3.003-3.003c0-1.18,0.691-2.203,1.691-2.692l0.084,0.631
		c-0.703,0.417-1.18,1.178-1.18,2.061c0,1.335,1.07,2.406,2.405,2.406c1.203,0,2.204-0.894,2.383-2.049H17.751z"
        />
      </g>
    </svg>
  );
};
