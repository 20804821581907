import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import FooterPageSection from '@hoffman/shared/src/components/page-sections/FooterPageSection';
import SectionPadding from '@hoffman/shared/src/components/SectionPadding';
import SectionBackgroundGroup from '@hoffman/shared/src/components/SectionBackgroundGroup';

// todo: put this in cms
const facebookUrl = 'https://www.facebook.com/Amaris-104168525205308';
const instagramUrl = 'https://www.instagram.com/amarisdc/';

const FooterBlock = (props) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            phone
            termsConditionPDF
          }
        }
      }
    `,
  );
  return (
    <SectionBackgroundGroup>
      <SectionPadding py="footer">
        <FooterPageSection
          phone={site.siteMetadata?.phone && site.siteMetadata.phone}
          termsAndCondition={
            site.siteMetadata?.termsConditionPDF &&
            site.siteMetadata?.termsConditionPDF
          }
          copyright={'©' + ' ' + new Date().getFullYear()}
          facebookUrl={facebookUrl}
          instagramUrl={instagramUrl}
        />
      </SectionPadding>
    </SectionBackgroundGroup>
  );
};

export default FooterBlock;
