import React from 'react';
import styled from 'styled-components';

interface HeadlineProps {
  text: string;
  size?: string;
  blockWidth?: string;
}

export const Headline = ({blockWidth, text, size}: HeadlineProps) => {
  return (
    <HeadlineContainer blockWidth={blockWidth} size={size}>
      {text}
    </HeadlineContainer>
  );
};

Headline.defaultProps = {
  text: 'Add body copy here...',
};

export default Headline;

const HeadlineContainer = styled.h1`
  margin: 0px;
  font-weight: ${({theme}) => theme.fontWeights.light};
  font-family: ${({theme}) => theme.fontFaces.heading};
  font-size: ${({size, theme}) => (size ? size : `${theme.fontSizes[6]}px`)};
  max-width: ${(props: HeadlineProps) => props.blockWidth};
  padding: 0;
  color: ${({theme}) => theme.colors.heading};
  text-decoration: none;
  line-height: ${({theme}) => theme.lineHeights.heading};

  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    font-size: ${({theme}) => theme.fontSizes[5]}px;
    line-height: ${({theme}) => theme.lineHeights.headingSecondary};
  }
`;
