import React from 'react';
import styled from 'styled-components';
import {HoffmanLogo} from '@hoffman/shared/src/components/HoffmanLogo/hoffmanLogo';
import {PhoneNumber} from '@hoffman/shared/src/components/PhoneNumber/phoneNumber';
import {BoxCarrot} from '@hoffman/shared/src/components/Carrot/carrot';
import {Eho} from '@hoffman/shared/src/components/EhoIcon/eho';
import {scrollTo} from '@hoffman/shared/src/utils/domUtils';
import FooterSocialMediaIcons from '@hoffman/shared/src/components/FooterSocialMediaIcons';
import PrivacyPolidyPdf from '../../../../../amaris/src/images/amaris_privacy-policy.pdf';
import TermsConditionsPdf from '../../../../../amaris/src/images/amaris_terms-and-conditions.pdf';
export interface FooterPageProps {
  phone: string;
  termsAndCondition: string;
  copyright: string;
  facebookUrl: string;
  instagramUrl: string;
}

export const FooterPageSection = (props: FooterPageProps): JSX.Element => {
  return (
    <Container>
      <TopContainer>
        <HoffmanContainer>
          <span>Sales By </span>
          <HoffmanLogo color="black" />
        </HoffmanContainer>
        <MobileSVGContainer>
          <LinkContainer>
            <BoxCarrot onClick={() => scrollTo()} top="true" />
          </LinkContainer>
        </MobileSVGContainer>
      </TopContainer>
      <HorizontalContainer>
        <PhoneNumber phone={props.phone} />
        <SocialIconsContainer>
          <FooterSocialMediaIcons
            facebookUrl={props.facebookUrl}
            instagramUrl={props.instagramUrl}
          />
        </SocialIconsContainer>
      </HorizontalContainer>
      <CopyrightContainer>
        <CopyrightContainerInner>
          <div>
            <TermsAndCondition href={TermsConditionsPdf} target="_blank">
              Terms &amp; Conditions
            </TermsAndCondition>
          </div>
          <div>
            <TermsAndCondition href={PrivacyPolidyPdf} target="_blank">
              Privacy Policy
            </TermsAndCondition>
          </div>
          <p>Copyright {props.copyright}</p>
        </CopyrightContainerInner>
        <div>
          <Eho color="grey" />
        </div>
      </CopyrightContainer>
      <SVGContainer>
        <LinkContainer>
          <BoxCarrot onClick={() => scrollTo()} top="true" />
        </LinkContainer>
      </SVGContainer>
    </Container>
  );
};

FooterPageSection.defaultProps = {
  phone: '201.918.7180',
  termsAndCondition: 'Terms and Condition',
  copyright: 'copyright',
};

export default FooterPageSection;

const Container = styled.div`
  display: grid;
  grid-template-columns: 380px 1fr 1fr 5%;
  font-family: ${({theme}) => theme.fontFaces.default};
  font-weight: ${({theme}) => theme.fontWeights.light};
  background-color: ${({theme}) => theme.colors.background};
  color: ${({theme}) => theme.colors.text};
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
  }
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  margin: 0 20px;
  > * {
    margin-right: 22px;
  }
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 20px 0;
    > * :first-child {
      margin-bottom: 12px;
    }
  }
`;

const SocialIconsContainer = styled.div``;
const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${({theme}) => theme.fontSizes[0]}px;
  align-items: center;

  div {
    padding: 0 10px;
    @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
      padding: 0 0 10px 0;
      padding-right: 10px;

      :last-child {
        padding-right: 0;
      }
    }
  }

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    align-items: flex-end;
    justify-content: space-between;
  }
`;

const CopyrightContainerInner = styled.div`
  display: flex;

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    flex-direction: column;
  }
  p {
    white-space: nowrap;
  }
`;

const LinkContainer = styled.div`
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;

  polyline {
    transition: transform ease-in 0.3s;
    -webkit-transform: translate(24px, 27px) rotate(-45deg)
      translate(-24px, -27px);
  }
  &:hover {
    polyline {
      transform: translate(24px, 22px) rotate(-45deg) translate(-24px, -27px);
    }
  }
  @media screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    &:hover {
      polyline {
        transform: translate(24px, 27px) rotate(-45deg) translate(-24px, -27px);
      }
    }
  }
`;

const TermsAndCondition = styled.a`
  white-space: nowrap;
  text-decoration: none;
  color: currentColor;
`;

const SVGContainer = styled.div`
  display: block;
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    display: none;
  }
`;

const TopContainer = styled.div`
  align-items: center;
  display: flex;

  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    align-items: flex-start;
  }
`;

const HoffmanContainer = styled.span`
  span {
    margin-bottom: 12px;
  }
  svg {
    margin: 0 5px;
  }
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    svg {
      margin: 0;
      width: 240px;
    }
  }
`;

const MobileSVGContainer = styled.div`
  display: none;
  margin-left: auto;
  margin-right: 0;
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    display: block;
  }
`;
