import React, {ReactNode} from 'react';
import {AmarisThemeName, AmarisColor} from '@hoffman/shared/src/theme';
import styled from 'styled-components';
import {FluidObject} from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

/**
 * Adds background to all children elements
 * within SectionBackgroundGroup.
 */

interface SectionBackgroundGroupProps {
  children: ReactNode;
  bgImg?: FluidObject; // For adding a bg image
  bgImgMobile?: FluidObject; // For adding a bg image
  bgColor?: AmarisColor; // For adding a Tishman Color
  altBg?: boolean; // Pulls in backgroundAlt color
  theme?: AmarisThemeName;
}

const StyledDiv = styled.div<SectionBackgroundGroupProps>`
  position: relative;
  ${({bgImg, bgColor, altBg, theme}) =>
    bgColor
      ? `background: ${theme.baseColors[bgColor]};`
      : altBg
      ? `background: ${theme.colors.backgroundAlt};`
      : `background: ${theme.colors.background};`}
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

const SectionBackgroundGroup = ({
  children,
  bgImg,
  bgImgMobile,
  bgColor,
  altBg,
}: SectionBackgroundGroupProps) => {
  const bgImage = bgImgMobile
    ? [
        bgImgMobile,
        {
          ...bgImg,
          media: `(min-width: 768px)`,
        },
      ]
    : bgImg;
  return (
    <StyledDiv bgColor={bgColor} altBg={altBg}>
      {bgImg ? (
        <StyledBackgroundImage fluid={bgImage}>
          {children}
        </StyledBackgroundImage>
      ) : (
        children
      )}
    </StyledDiv>
  );
};

export default SectionBackgroundGroup;
