import React from 'react';
import {useState} from 'react';

export const BoxCarrot = ({hoverColor, top, link, onClick}) => {
  const [isShown, setIsShown] = useState(false);
  let hColor = hoverColor === undefined ? '#414448' : hoverColor;
  return (
    <>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span style={{padding: '5px'}}>
            <svg
              width="48px"
              viewBox="0 0 48 68"
              version="1.1"
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
            >
              <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Megafooter"
                  transform="translate(-1260.000000, -410.000000)"
                >
                  <g id="Footer">
                    <g transform="translate(0.000000, 352.000000)">
                      <g
                        id="Back-to-TOp"
                        transform="translate(1260.000000, 58.000000)"
                      >
                        <rect
                          id="Rectangle"
                          stroke={isShown ? hColor : '#414448'}
                          x="0.5"
                          y="0.5"
                          width="47"
                          height="44"
                        ></rect>
                        <text
                          id="top"
                          fontFamily="Roboto-Regular, Roboto"
                          fontSize="13"
                          font-weight="normal"
                          line-spacing="25"
                          letterSpacing="3.81875"
                          fill={top === 'true' ? '#414448' : 'transparent'}
                        >
                          <tspan x="8.82597656" y="63">
                            TOP
                          </tspan>
                        </text>
                        <polyline
                          id="Path"
                          stroke={isShown ? hColor : '#414448'}
                          transform="translate(24.000000, 27.000000) rotate(-45.000000) translate(-24.000000, -27.000000) "
                          points="16 19 32 19 32 35"
                        ></polyline>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </a>
      ) : (
        <span onClick={onClick} style={{padding: '5px'}}>
          <svg
            width="48px"
            viewBox="0 0 48 68"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            <g
              id="Symbols"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Megafooter"
                transform="translate(-1260.000000, -410.000000)"
              >
                <g id="Footer">
                  <g transform="translate(0.000000, 352.000000)">
                    <g
                      id="Back-to-TOp"
                      transform="translate(1260.000000, 58.000000)"
                    >
                      <rect
                        id="Rectangle"
                        stroke={isShown ? hColor : '#414448'}
                        x="0.5"
                        y="0.5"
                        width="47"
                        height="44"
                      ></rect>
                      <text
                        id="top"
                        fontFamily="Roboto-Regular, Roboto"
                        fontSize="13"
                        fontWeight="normal"
                        line-spacing="25"
                        letterSpacing="3.81875"
                        fill={isShown ? hColor : '#414448'}
                      >
                        <tspan x="8.82597656" y="63">
                          TOP
                        </tspan>
                      </text>
                      <polyline
                        id="Path"
                        stroke={isShown ? hColor : '#414448'}
                        transform="translate(24.000000, 27.000000) rotate(-45.000000) translate(-24.000000, -27.000000) "
                        points="16 19 32 19 32 35"
                      ></polyline>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      )}
    </>
  );
};

export const DownCarrot = ({hoverColor, link}) => {
  const [isShown, setIsShown] = useState(false);
  let hColor = hoverColor === undefined ? color : hoverColor;
  return (
    <>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span style={{padding: '5px'}}>
            <svg
              width="15px"
              viewBox="0 0 15 10"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
            >
              <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Drop-down"
                  transform="translate(-372.000000, -10.000000)"
                  stroke={isShown ? hColor : 'black'}
                  strokeWidth="2"
                >
                  <g transform="translate(0.000000, -3.000000)" id="Path">
                    <polyline
                      transform="translate(379.500000, 14.500000) rotate(-315.000000) translate(-379.500000, -14.500000) "
                      points="384 10 384 19 375 19"
                    ></polyline>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </a>
      ) : (
        <span style={{padding: '5px'}}>
          <svg
            width="15px"
            viewBox="0 0 15 10"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            <g
              id="Symbols"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Drop-down"
                transform="translate(-372.000000, -10.000000)"
                stroke={isShown ? hColor : 'black'}
                strokeWidth="2"
              >
                <g transform="translate(0.000000, -3.000000)" id="Path">
                  <polyline
                    transform="translate(379.500000, 14.500000) rotate(-315.000000) translate(-379.500000, -14.500000) "
                    points="384 10 384 19 375 19"
                  ></polyline>
                </g>
              </g>
            </g>
          </svg>
        </span>
      )}
    </>
  );
};
